.img-saved {
  height: 33vh;
}

.img-user-saved {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  border-style: ridge;
  object-fit: cover;
}
.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}
.parentContainer {
  text-align: center;
  color: white;
  padding-bottom: 2px;
}

.top-left-saved {
  position: absolute;
  top: 2px;
  left: 2px;
  /* padding: 5px; */
  border-radius: 100%;
  border-color: #ffffff;
  /* text-align: center; */
  height: 30px;
  /* border-style: ridge; */
  background-color: #ffffff73;
  width: 30px;
}

.top-right-saved {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  text-align: start;
}

.bottom-right {
  position: absolute;
  bottom: 10px;
  right: 15px;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.651);
  text-align: start;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.651);
  text-align: start;
}

.container-detail {
  display: flex;
  height: 52px;
  justify-content: flex-end;
  align-items: flex-start;
}

.container-detail .contentLeft {
  flex: 1;
  display: inline-grid;
}
.container-detail .contentRight {
  width: auto;
}
.container-detail .contentEnd {
  width: 50px;
  top: 7px;
  position: relative;
  text-align: end;
}
.bottom-left-content {
  position: absolute;
  bottom: 10px;
  /* left: 5px; */
  padding: 2px;
  /* border-radius: 5px; */
  background-color: rgba(0, 0, 0, 0.651);
  text-align: start;
  line-height: 1;
  width: 100vw;
  height: 50px;
}


/* .cut { 
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: "14px";
  font-weight: "800";  
  margin: 0;
  } */
