.info {
  padding:10px;
  width: auto;
  /* background-color: #ffba20; */
}

.info-button {
  padding: 5px 0px 15px 0px;
  float: left;
  width: 90%;
}

.container-info {
  display: flex;
}
.container-info > div {
  flex: 1; /*grow*/
}


.left-column
{
  float:left;
  width:30%;
}

.right-column
{
  float:right;
  width:70%;
}

.cultural-space {
  width:100%;
  display: inline-flex;
}

.bold {
  font-weight: 900!important;
}

.left-close {
  margin: -5px 0 -30px 88%;
}
