.responsiveWel {
  max-width: 100vw;
  height: auto;
}

.responsiveImg {
  max-width: 100%;
  height: auto;
}

.css-ptiqhd-MuiSvgIcon-root {
  width: 3em!important;
  height: 3em!important;
  fill:#004c43!important;
}

.floatBox {
  /* width: 50px; */
  /* height: 50px; */
  line-height: 50px;
  text-align: center;
  /* float:left; */
  white-space: nowrap;
  max-width: 95%;
  overflow: scroll;
}

.container {
  /* width: 200px; */
  height: 200px;
  display: flex;
  justify-content: center;
  overflow: auto;
}

figure {
	/* width: 300px; */
	/* height: 200px; */
	margin: 0;
	padding: 0;
	/* background: #fff; */
	/* overflow: hidden; */
}
figure:hover+span {
	bottom: -36px;
	opacity: 1;
}
/* Zoom In #1 */
/* Zoom In #1 */
.hover01 figure img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover01 figure:hover img {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

.icone-domini {
  width: 96px;
  height: 96px;
  object-fit: cover;
  padding: 8px;
  border-radius: 100px;
  border-style: solid hidden;
  margin: 20px;
}

/* elimina l'indicatore del TAB selezionato solo per welcome */
.css-1cku3yl-MuiTabs-indicator{
  height: 0px!important;
}
