/* .leaflet-popup-content-wrapper {
    background: #2ce897;
    color: #eee;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    border-radius: 0px;
  }
  
.leaflet-popup-content-wrapper {
    background: red;
  } */

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  /* border: 1px solid; */
  text-align: center;
  width: 28px;
  height: 28px;
  font: 22px/24px Tahoma, Verdana, sans-serif;
  color: #004c43;
  text-decoration: none;
  /* background: #ffba20; */
  border-radius: 50%;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #ffba20;
}

.marker-svg {
  position: absolute;
  top: 50;
  left: 60;
  height: 100%; /* fix issue */
  width: 100%; /* in safari */
}
