.left {
  float: left;
}

.right {
  float: right;
}

footer:after,
.login-form:after,
header:after {
  content: "";
  display: table;
  clear: both;
}

.ui-panel {
  /* margin: 0 auto; */
  margin-top: 50px;
  /* width: 360px; */
  /* height: auto; */
  background-color: #ffba20;
  color: #004c43;
  /* border: 1px 0px 1px 1px solid rgb(190, 160, 22); */
  /* box-shadow: 0px 0px 6px rgb(255 255 255 / 30%); */
  /* position: absolute; */
  /* margin-top: -130px; */
  /* margin-left: -181px; */
  /* top: 50%; */
  left: 50%;
}

/* header {
    height: 46px;
    border-bottom: 1px solid #161616;
    line-height: 46px;
    padding: 0 28px;
    font-size: 0.65em;
    font-weight: 600;
    letter-spacing: 0.3em;
  }
  header .logo {
    text-transform: uppercase;
  }
  header span {
    color: #ffdd00;
  } */

.login-form {
  padding: 18px 28px 0 28px;
}
.login-form .subtitle {
  font-size: 0.92em;
  font-weight: 600;
}
.login-form input {
  font-size: 1.05em;
  font-weight: 300;
  /* margin-top: 18px;
  padding: 10px 8px; */
  width: 288px;
}

footer {
  padding: 26px 28px 22px 28px;
  font-size: 0.82em;
}
/* footer .social-login i:first-child {
    margin-left: 4px;
  }
  footer .form-actions a {
    padding: 4px 8px;
  } */
footer .register {
  background-color: #ffdd00;
  color: black;
  border-radius: 2px;
}

/* body {
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  } */

.overlay {
  /* position: absolute;
    top: 0;
    left: 0; */
  width: 100vw;
  background-color: #ffba20;
  height: 100%;
  min-height: 100vh;
}

.mt30 {
  margin-top: 30px;
}

.mt100 {
  margin-top: 100px;
}

/* .vline {
    border-left: 6px solid gray;
    position: relative;
    height: 500px;
    left: 50%;
  }

  .vline:before {
    content: '';
    border-left: 6px solid red;
    height:100px;
    position:absolute; 
  } */
