@import url("https://fonts.googleapis.com/css2?family=Mansalva&family=Mynerve&display=swap");

.hashBoxAdd {
  line-height: 50px;
  text-align: initial;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  left: -10px;
}

.hashAdd {
  
  left: 0px;
  color: #004c43!important;
}

.mansalva-h6 {
  margin: 0;
  /* font-family: 'Mansalva' !important; */
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 1.6;
  letter-spacing: 0.118em;
}

.mansalva-hasbuttons {
  margin: 0;
  font-family: "Mansalva" !important;
  font-weight: 900;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.2rem !important;
  text-shadow: 0px 1px #fff;
}

.severity-dot {
  /* font-family: 'Mansalva'; */
  font-weight: 400;
  font-size: 1.35rem;
  color: #004c43;
}

.scroll-added {
  text-align: start;
  overflow-x: scroll;
  white-space: nowrap;
  width: auto;
  display: inline-flex;
  height: 280px;
}
.App {
  font-family: sans-serif;
  /* text-align: center; */
  background-color: #f6f9fe;
}
.image-item {
  display: flex;
  margin: 10px 0;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.buttonUpload {
  height: 40px;
}
/* .box-upload {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
} */

.box-upload {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-left: 30px;
  }
  
  label {
    display: none;
  }

  .progress {
      background-color: #ffffff70;
      width: 100%;
      position: absolute;
      padding: 40%;
      z-index: 999;
      height: 100%;  
  }

