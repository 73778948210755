@import url("https://fonts.googleapis.com/css2?family=Mansalva&family=Mynerve&display=swap");

.appbarstyle {
  margin: 54px 0px 0px 0px;
  background-color: #ffb639 !important;
  text-align: left;
}

.appbarstyle2 {
  margin: 52px 0px 0px 0px;
}

.appbarstyle3 {
  margin: 186px 0px 50px 0px;
  /* display: inline-flex; */
  /* height: -webkit-calc(100vh + 30em);
     height: -moz-calc(100% + 30em);
    height: -o-calc(100% + 30em); */
}

.appbarstyle4 {
  margin: 60px 0px 55px 0px;
}

.hashHOME {
  /* margin-left: 20px !important; */
  /* left: 80px; */
  color: #004c43 !important;
  background-color: #ffba20 !important;

  /* padding: 4rem 8rem;
    display: inline-block;
    border: 3px solid #1de2cb;
    font-size: 2.5rem;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    background: #ffffff;
    position: relative; */

  /* &::before {
    content: "";
    border: 2px solid #004c43;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(-0.5deg);
    border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%; 
  }*/
}

.hashBox {
  /* width: auto; */
  height: 50px;
  /* line-height: 50px; */
  /* text-align: center; */
  /* float: left; */
  white-space: nowrap;
  /* max-width: 95%; */
  width: 100%;
  overflow-x: scroll;
  /* overflow-y: hidden; */
  /* position: absolute; */
  /* top: 225px; */
  /* background: #ffb639!important; */
  /* margin-left: 75px; */
}

.centerbkg {
  background: url("../../public/images/Image 1_SMART_0.png") no-repeat center;
  background-color: #ffb639;
  background-size: 230px;
  height: 99px;
}

.scroll-category {
  /* text-align: start;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100vw; */
  text-align: start;
  white-space: nowrap;
  overflow-x: scroll;
}
.explore {
  /* margin-left: 20px;
  margin-top: 54px;
  z-index: 9999;
  position: fixed; */
  display: flex;
  margin-left: 15px;
}
.main-flex {
  display: inline-flex;
}

.mansalva-hasbuttons {
  margin: 0;
  font-family: "Mansalva" !important;
  font-weight: 900;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.2rem !important;
  text-shadow: 0px 1px #fff;
}
.mansalva-explore {
  font-family: "Mansalva" !important;
  font-size: 25px;
  letter-spacing: -0.1rem;
  text-shadow: 2px 2px #000000;
}
