.bottom-left-incident {
  position: absolute;
  bottom: 10px;
  /* left: 5px; */
  padding: 2px;
  /* border-radius: 5px; */
  background-color: rgba(0, 0, 0, 0.651);
  text-align: start;
  line-height: 1;
  width: 100vw;
  height: 50px;
}
.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.MuiAlert-icon {
  display: none!important; 
}

.top-left-content {
  position: absolute;
  top: 2px;
  left: 2px;
  /* padding: 5px; */
  border-radius: 100%;
  border-color: #ffffff;
  /* text-align: center; */
  height: 30px;
  /* border-style: ridge; */
  background-color: rgb(0 0 0 / 50%);
  width: 30px;
}

.incident-detail {
  display: flex;
}
.incident-detail .contentLeft {
  flex: 1;
  display: inline-grid;
}
.incident-detail .contentRight {
  width: auto;
}
.incident-detail .contentEnd {
  width: 50px;
  position: relative;
  text-align: end;
}
.img-user-content {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  border-style: ridge;
  object-fit: cover;
}

/* .truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } */

.cut {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: "14px";
  font-weight: "800";
  margin: 0;
}
.top-right-content {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  text-align: start;
}